import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export default {
  /**
   * Device
   */
  async upsertDevice(payload: any = {}) {
    return (await firebase.app().functions('asia-east2').httpsCallable('device-upsertDevice')(payload)).data;
  },
  async updateDevicePointXY(id, pointX, pointY) {
    return (await firebase.app().functions('asia-east2').httpsCallable('device-upsertDevice')({ id, pointX, pointY })).data;
  },
  async deleteDevicePoint(id) {
    return (await firebase.app().functions('asia-east2').httpsCallable('device-upsertDevice')({ id, projectId: '' })).data;
  },
  async getTodayDeviceAlerts(deviceId: any = null, projectId: any = null) {
    return (await firebase.app().functions('asia-east2').httpsCallable('device-getTodayDeviceAlerts')({ deviceId, projectId })).data; 
  },

  /**
   * AI Cam Edge
   */
  async fetchEdgeChannels(device) {
    return (await firebase.app().functions('asia-east2').httpsCallable('device-fetchEdgeChannels')({ device })).data; 
  },
}