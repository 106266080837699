
// Vue reactivity
import { defineComponent, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue';

// icons
import { close, person, call, mail } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonIcon,
        IonButtons, IonButton, IonInput, IonGrid, IonCol, IonRow, IonText,
        modalController, loadingController, toastController, alertController, } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

// Capacitor
import { BleClient, numberToUUID, } from '@capacitor-community/bluetooth-le';

export default defineComponent({
  name: 'BLEScanModal',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonSpinner, IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonInput, IonText,
                IonGrid, IonCol, IonRow, },
  setup() {
    // 1. declare state variables (ref to make them reactive)
    const { closeModal, getRelativeDate, formatDate, } = utils();
    const store = useStore();

    const nearbyDevices = ref({});

    const scanNearbyDevices = async () => {
      await BleClient.initialize();
      await BleClient.requestLEScan(
        {
          services: [],
          allowDuplicates: true,
        },
        (result) => {
          const { device, rssi, manufacturerData, serviceData, uuids, } = result;
          console.log(manufacturerData);
          console.log(serviceData);
          console.log('received new scan result', result);
          if ("0000dec0-0000-1000-8000-00805f9b34fb" in serviceData) {
            const deviceId = device.deviceId.replace(/:/g, "").toLowerCase();
            nearbyDevices.value[deviceId] = { mac: device.deviceId, device, rssi, uuids, manufacturerData, serviceData, lastSeen: new Date() };
          }
        }
      );
      /*
      const device = await BleClient.requestDevice({
        services: ["0000dec0-0000-1000-8000-00805f9b34fb", "27182818-2845-9045-2353-602874713526"],
        //services: [numberToUUID(0x1800), numberToUUID(0x1801), numberToUUID(0xFF90), numberToUUID(0xFFE0), numberToUUID(0xFEF5)],
        //optionalServices: [numberToUUID(0xdec0)],
        //namePrefix: '',
      });
      console.log(device.deviceId)
      */
    }

    onMounted(() => {
      scanNearbyDevices();
    })

    onBeforeUnmount(() => {
      BleClient.stopLEScan();
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, mail, person, call,

      // variables
      nearbyDevices,

      // methods
      closeModal, getRelativeDate, formatDate,
    }
  }
});
