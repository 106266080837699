import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export default {
  /**
   * Asset
   */
  async batchCreateNewAnchorAssets(payload: any = {}) {
    return (await firebase.app().functions('asia-east2').httpsCallable('asset-batchCreateNewAnchorAssets')(payload)).data;
  },
  async createNewAsset(payload: any = {}) {
    return (await firebase.app().functions('asia-east2').httpsCallable('asset-createNewAsset')(payload)).data;
  },
  async updateAsset(payload: any = {}) {
    return (await firebase.app().functions('asia-east2').httpsCallable('asset-updateAsset')(payload)).data;
  },
  async linkChildAssetToDevice(childAssetId, deviceId, parentId, projectId, oldDeviceId = null) {
    return (await firebase.app().functions('asia-east2').httpsCallable('asset-linkChildAssetToDevice')({ childAssetId, deviceId, parentId, projectId, oldDeviceId, })).data;
  },
  async updateAssetPointXY(id, pointX, pointY) {
    return (await firebase.app().functions('asia-east2').httpsCallable('asset-updateAsset')({ id, pointX, pointY })).data;
  },
  async deleteAssetPoint(id) {
    return (await firebase.app().functions('asia-east2').httpsCallable('asset-updateAsset')({ id, projectId: '' })).data;
  },
  async cleanupUnlinkedAssets(projectId, workLocationId) {
    return (await firebase.app().functions('asia-east2').httpsCallable('asset-cleanupUnlinkedAssets')({ projectId, workLocationId })).data;
  },
}